import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import ElementUI from 'element-plus'

import * as Icon from '@element-plus/icons-vue'

export function setupElementPlus(app) {
  app.use(ElementUI, { locale })
  Object.keys(Icon).forEach((key) => {
    app.component(key, Icon[key])
  })
}