import { createRouter, createWebHashHistory } from 'vue-router'
// createWebHashHistory
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/Home/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/login/index.vue')
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('../pages/Record/index.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../pages/Order/index.vue')
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('../pages/Result/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
