import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import { setupElementPlus } from '@/assets/js/elementPlus'
const app = createApp(App)
setupElementPlus(app)

app.use(router).use(Vant).mount('#app')
