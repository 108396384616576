<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view></router-view>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const router = useRouter()
const route = useRoute()
onMounted(() => {
  router.beforeEach((to, from, next) => {
    if (window._czc) {
      window._czc.push(['_trackPageview', to.fullPath, from.fullPath])
    }
    next()
  })
})
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

html {
  @media only screen and (max-width: 320px) {
    font-size: 85.3333px;
  }

  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 26.6666vw;
  }

  @media only screen and (min-width: 640px) {
    font-size: 170.6666px;
  }
}

#app {
  background-color: #864a30;
}

.footer-remark {
  text-align: center;
  font-size: 12px;
  color: #CDD0D6;
}
</style>
